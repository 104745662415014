import axios from "axios";
import SafeArea from "components/safearea";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";
// import CheckoutForm from "./checkout";
import { dollarFormatter, dollarInputField } from "utils";
import { TInvoice } from "types/invoice";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Button } from "components/button";
// import ACHTransaction from "./ach_transaction";
import { getStripeAccountId } from "./api/getStripeAccountId";
import { ElementsWrapper } from "./elements";
import PaymentForm from "./payment-form";
import { set } from "lodash";
import { useTranslation } from 'react-i18next';
import Spinner from "components/spinner";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

// const getStripePromiseDirect = async (stripeAccount: string) =>
//   await loadStripe(process.env.REACT_APP_STRIPE!, {
//     stripeAccount: stripeAccount,
//   });

export default function Invoice(props: any) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.invoice.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [succeeded, setSucceeded] = useState(false);
  const [isDirect, setIsDirect] = useState(true);
  const [stripeAccountId, setStripeAccountId] = useState<string>("");
  const [customAmount, setCustomAmount] = useState<number | null>(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disabledInput, setDisabledInput] = useState(false);

  const invoiceQuery = useQuery<TInvoice>(
    ["invoice", props.match.params.id],
    async () =>
      await axios
        .get(
          `
            ${process.env.REACT_APP_API}/api/v1/consumer/invoices/${props.match.params.id}
          `
        )
        .then((res) => res.data)
  );

  useEffect(() => {
    getStripeAccountId(props.match.params.id).then((data: string) => {
      setStripeAccountId(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoiceQuery.data) {
      setCustomAmount(invoiceQuery.data.amount_due);
    }
  }, [invoiceQuery.data]);

  if (invoiceQuery.isLoading) {
    return (
      <div className="space-y-8 p-4 min-h-[400px] flex flex-col justify-center items-center text-center text-secondary">
        <h1 className="text-2xl ml-2">
          {tr("Loading")}
          <span className="animate-ellipsis [animation-delay:-0.9s]">.</span>
          <span className="animate-ellipsis [animation-delay:-0.6s]">.</span>
          <span className="animate-ellipsis [animation-delay:-0.3s]">.</span>
        </h1>
        <Spinner size={40} className="text-gray-400" />
      </div>
    );
  }

  if (succeeded) {
    return (
      <div className="space-y-8 p-4 min-h-[400px] flex flex-col justify-center items-center text-center">
        <RiCheckboxCircleFill className="text-7xl text-green-600" />
        <div>
          {" "}
          <h1 className="">{tr("Thanks for your payment!")}</h1>
          <p className="max-w-prose">
            {tr("Your payment was successful, and your invoice is fully paid. You will be receiving a receipt in your inbox.")}
          </p>
        </div>

        <Button primary onClick={() => window.location.reload()}>
          {tr("View Invoice")}
        </Button>
      </div>
    );
  }

  if (!stripeAccountId || !invoiceQuery.data) return null;

  const data = invoiceQuery.data;

  let taxTotal = 0;

  const populateLineItems = data.products?.map((product) => {
    const productRate = product.quantity * product.price;

    return (
      <div key={product.id} className="items-center flex justify-between">
        <div className="flex flex-col py-2">
          <span className="font-semibold">{product.label}</span>
          <span className="text-sm italic">
            {" "}
            {product.short_description}{" "}
            {product.short_description && product.long_description ? "·" : ""}{" "}
            {product.long_description}
          </span>
          <span className="text-xs">
            {" "}
            {product.quantity} x {dollarFormatter(product.price)}
          </span>
        </div>

        <span className="">{dollarFormatter(productRate)}</span>
      </div>
    );
  });

  const populateTaxes = data.taxes?.map((tax) => {
    let taxRate =
      tax.type === "fixed"
        ? tax.rate
        : Math.round((tax.rate / 100) * data.sub_total);

    taxTotal = taxTotal + taxRate;

    return (
      <div key={tax.id} className="flex justify-between">
        <span>{`${tax.label} (${
          tax.type === "fixed" ? dollarFormatter(tax.rate) : `${tax.rate}%`
        })`}</span>
        <span>{dollarFormatter(taxRate)}</span>
      </div>
    );
  });

  const invoiceId = props.match.params.id;

  // Used for the "Pay" button
  const handlePayButton = () => {
    if (customAmount && customAmount <= 99) {
      setErrorMessage("Please enter at least $1.00.");
      setShowPaymentForm(false);
      setDisabledInput(false);
    } else if (customAmount && customAmount > data.amount_due) {
      setErrorMessage("You cannot pay more than the total amount due.");
      setShowPaymentForm(false);
      setDisabledInput(false);
    } else if (!showPaymentForm) {
      setErrorMessage(null); 
      setDisabledInput(true);
      setShowPaymentForm(true);
    } else {
      setShowPaymentForm(false);
      setDisabledInput(false);
    }
  };

  return (
    <SafeArea padding style={{ maxWidth: "600px" }}>
      <div className="space-y-4">
        <div>
          <SInvoiceId>{tr("Invoice")} #{data.id}</SInvoiceId>
          <h3>{data.company}</h3>
          <h4 style={{ fontWeight: 500 }}>{tr("To")}: {data.customer.name}</h4>
        </div>

        {/* <PaymentProgress
        {...{
          taxTotal,
          productTotal,
          amount_captured: data.payment?.amount_captured || 0,
        }}
      /> */}
        <div className="divide-y">
          <div className="py-4">
            <h2>{tr("Invoice Details")}</h2>
          </div>
          <div className="py-4 divide-y">
            <div>
              <ItemHeader className="text-sm flex justify-between">
                <div>{tr("ITEMS")}</div>
                <div>{tr("COST")}</div>
              </ItemHeader>
              {populateLineItems}
            </div>
            <div className="py-4 text-md">
              <div className="py flex justify-between">
                <span>{tr("Subtotal")}</span>
                <span>{dollarFormatter(data.sub_total)}</span>
              </div>
              {populateTaxes}
            </div>
            <div className="py-4">
              <div className="py flex justify-between">
                <span>{tr("Total")}</span>
                <span>{dollarFormatter(data.actual_total)}</span>
              </div>
            </div>
            <div className="py-4">
              <div className="py flex justify-between">
                <span>{tr("Paid")}</span>
                <span>-{dollarFormatter(data.paid_total || 0)}</span>
              </div>
            </div>

            <div className="border-y border-slate-400 py-4 font-semibold text-lg flex justify-between">
              <span>{tr("Amount Due")}</span>
              <span>{dollarFormatter(data.amount_due)}</span>
            </div>

            <div className="pt-5 pb-4 flex justify-between">
              <label htmlFor="customAmount" className="pt-2">
                {tr("Enter Amount to Pay")}:
              </label>
              <input
                id="customAmount"
                disabled={disabledInput}
                value={dollarFormatter(customAmount) || ""}
                onChange={(e) => {
                  const cents = dollarInputField.format(e.target.value);
                  setCustomAmount(cents);
                }}
              />
            </div>

            <div className="pt-4 flex justify-between">
              <div className="pt-3">
                {errorMessage && (
                  <div className="text-red-500">{tr(errorMessage)}</div>
                )}
              </div>
              <Button
                primary={!disabledInput}
                className="float-right"
                onClick={handlePayButton}
              >
                {disabledInput ? tr("Edit") : tr("Pay")}
              </Button>
            </div>
          </div>
        </div>

        {/** 
         * Every time this is rendered / re-rendered it creates new Stripe call: payment_intent.created
         * This is so Stripe takes the current input value and creates a new payment intent
         *  */}
        {showPaymentForm && !errorMessage && (
          <div className="divide-y">
            <h2 className="pb-4">{tr("Payment Method")}</h2>
            <div className="py-4">
              <PaymentForm
                invoiceId={invoiceId}
                companyStripeAccountId={stripeAccountId}
                customAmount={customAmount}
                amountDue={data.amount_due}
              />

              {data.payment && (
                <div>
                  <div className="font-bold capitalize">
                    {data.payment.network
                      ? `${data.payment.network} ending in ${data.payment.last4}`
                      : "ACH"}
                  </div>
                  <div className="text-3xl">
                    {dollarFormatter(data.payment.amount_captured)}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

      </div>
    </SafeArea>
  );
}

const ItemHeader = styled.div`
  font-weight: bold;
  padding: 0.5rem 0;
`;

const Box = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0;
  > div {
    > h1 {
      padding: 0;
      margin: 0;
    }
  }
  margin-bottom: 1rem;
`;

const SInvoiceId = styled.span`
  font-weight: 500;
  font-size: 0.9rem;
  color: #949599;
`;

type ProgressProps = {
  amount_captured: number | null;
  taxTotal: number;
  productTotal: number;
};

const PaymentProgress = ({
  amount_captured,
  taxTotal,
  productTotal,
}: ProgressProps) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.invoice.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const total = taxTotal + productTotal;

  const paid = amount_captured || 120;

  const percent = ((paid / total) * 100).toFixed(2);

  return (
    <SPayment>
      <h4>{tr("Progress")}</h4>
      <div>
        <span>{percent}%</span>
        <span>
          {dollarFormatter(paid)} / {dollarFormatter(total)}
        </span>
      </div>
      <SBar percent={percent} />
    </SPayment>
  );
};

const SPayment = styled.div`
  background: #f0f0f0;
  padding: 1rem;
  border-radius: 5px;
  font-weight: 500;
  margin-top: 0.5rem;
  > div:nth-child(2) {
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    > span:first-child {
      color: #ff4f00;
    }
    > span:nth-child(2) {
      font-size: 0.9rem;
    }
  }
`;

const SBar = styled.div<{ percent: string }>`
  background: white;
  border-radius: 25px;
  height: 6px;
  position: relative;
  overflow: hidden;
  width: 100%;
  &:before {
    content: "";
    background: #ff4f00;
    width: ${(props) => props.percent}%;
    height: 100%;
    position: absolute;
    left: 0;
  }
`;
